// 自定义底板
import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//自定义布局列表
export function customLayoutList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/customLayout/list',
    method: 'post',
    data
  })
}

//自定义布局创建
export function createCustomLayout(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/customLayout/create',
    method: 'post',
    data
  })
}

//自定义布局删除
export function deleteCustomLayout(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/customLayout/delete',
    method: 'post',
    data
  })
}

//自定义布局启用状态修改
export function enableUpdateCustomLayout(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/customLayout/enableUpdate',
    method: 'post',
    data
  })
}

//自定义布局修改
export function updateCustomLayout(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/customLayout/update',
    method: 'post',
    data
  })
}

//自定义布局详情
export function customLayoutDetail(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/customLayout/detail',
    method: 'post',
    data
  })
}

//单品自定义底板列表
export function singleCustomFloorList(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/bottomBoard/singleBottomBoardList',
    method: 'post',
    data
  })
}

//组合产品自定义模板列表
export function combinationmCustomFloorList(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/bottomBoard/combinedBottomBoardList',
    method: 'post',
    data
  })
}
