<template>
  <div class="done-btn-wrapper">
    <el-button @click="doCancel">取消</el-button>

    <el-button v-if="hasDoSubmit" :loading="loading" type="primary" class="next-btn" @click="doSubmit">确定</el-button>
  </div>
</template>

<script>
import { fabricInstanceMixin } from '../../../mixins'
import { ORIGIN_SHOW_AREA_W, ORIGIN_SHOW_AREA_H, DESIGN_AREA_W, DESIGN_AREA_H } from '@/utils/constant'
import { createCustomLayout, updateCustomLayout } from '@/api/product/customFloorApi'
import { base64ToFile, getUUID } from '@/utils'
export default {
  mixins: [fabricInstanceMixin],

  data() {
    return {
      loading: false
    }
  },

  props: {
    hasDoSubmit: Boolean
  },

  methods: {
    doCancel() {
      this.$router.replace({
        path: '/user/customFloorList'
      })
    },

    async doSubmit() {
      try {
        this.loading = true
        const params = await this.formatCnavasData()
        let apiFunc = createCustomLayout
        if (this.isEdit) {
          apiFunc = updateCustomLayout
        }
        if (params?.list?.length === 0) {
          return this.$message.warning('请设置布局组块信息')
        }
        const res = await apiFunc(params)
        if ($SUC(res)) {
          this.$message.success('操作成功')
          this.doCancel()
        }
      } finally {
        this.loading = false
      }
    },

    async formatCnavasData() {
      const displayImagePath = await this.uploadPicToOss()
      if (!displayImagePath) return
      const os = this.instance.getObjects()
      const params = {
        displayImagePath,
        width: ORIGIN_SHOW_AREA_W,
        height: ORIGIN_SHOW_AREA_H,
        code: getUUID(),
        list: this.formatObjects(os)
      }
      //编辑
      if (this.isEdit) {
        params.id = this.customFloorData.id
      }
      return params
    },

    async uploadPicToOss() {
      const base64Url = this.instance.canvas.toDataURL({
        format: 'png',
        quality: 1
      })
      const file = base64ToFile(base64Url, `base64_custom_floor_layout.png`)
      const displayImagePathObj = await $uploadOSSPics([
        {
          files: [file],
          prop: 'displayImagePath',
          dirPrefix: $ossDirMapWithType['customFloorLayout']
        }
      ])
      if (!displayImagePathObj) return false
      return displayImagePathObj.displayImagePath
    },

    formatObjects(objects) {
      return objects.map((item, level) => {
        //定制画布相对原始画布尺寸
        const {
          width,
          height,
          left: centerX,
          top: centerY,
          angle: rotation,
          scaleX,
          scaleY
        } = this.getObjectToOrigin(item)
        return {
          level,
          width,
          height,
          centerX,
          centerY,
          scaleX,
          scaleY,
          rotation
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.done-btn-wrapper {
  width: 100%;
  text-align: center;
}
</style>
